import React from 'react';


function EmailSuccess({email}) {
    

    // Function to create the anchor tag
    function renderAnchor() {
        return <a href='#hero' className='btt'>Go Back To Top</a>;
    }

    // Handle click function
    const handleClick = () => {
        // You can perform any action here, like scrolling to the anchor
        const anchor = document.getElementById('hero');
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className={'email-status'}>
            <div className={'email-info'}>
                
                <h1>Thanks for subscribing!</h1>
                <p>Updates concerning project developments and listing will be sent to your email.</p>

               <button type={"submit"} onClick={handleClick}>Go Back To Top</button> 
            </div>
        </div>
    );
}

export default EmailSuccess;