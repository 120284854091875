import React, {useEffect, useState} from 'react';
import './NewsletterForm.css'
import { earth5 } from 'assets';
import EmailSuccess from "./EmailSuccess";


function NewsLetterForm() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 700)
    const [emailStatus, setEmailStatus] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)

    const handleResize = () => {
        if (window.innerWidth <= 700) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    },[])

   


    function ValidateEmail(e) {
        e.preventDefault()
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (email.length !== 0 && validRegex.test(email)) {
            Submit(e)
            setEmailStatus(true);
        setEmailError(false);
        } else {
            setEmailError(true)
        }
    }


    function Submit(e) {
        e.preventDefault()
        const formEle = document.querySelector("form");
        const formDatab = new FormData(formEle);
        fetch(
            "https://script.google.com/macros/s/AKfycbxJJRC3ZVClNvQQkqWpHP0laHUtID1IHKhFOKX9smZXzRIeg_j5bnLHQEDDWr2HNTZo/exec",
            {
                method: "POST",
                body: formDatab
            }
        )
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
                setEmailError(true)
            });
        setEmailStatus(true)
    }

    return (
        <div className='newsletter'>
        <div className='letter'> 
        
            {emailStatus ? <EmailSuccess email={email}/> : (<div className={'news'}>
                
                <div className={'news-letter-form'}>
                   
                    <div className={'news-info'}>
                    
                        <h1>Stay updated!</h1>
                        <p>Join 80,000+ community members receiving monthly updates</p>

                        

                        <form className={"form"} onSubmit={(e) => ValidateEmail(e)}>
                            <div className={'email-group'}>
                                <div className={'error-tab'}>
                                    <label htmlFor={"email"} style={{width: 200}}>Email Address</label>
                                    {emailError? <p>Valid email required</p> : ''}
                                </div>

                                <input
                                    placeholder={"you@email.com"}
                                    name={"Email"}
                                    id={"email"}
                                    style={{
                                        border: emailError ? '1px solid red': '',
                                        background: emailError ? "rgb(252, 230, 227)": '',
                                        color: emailError ? "red": ''}}
                                    onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                            <button name="Name" type={"submit"}>Subscribe to monthly newsletter</button>
                        </form>
                    </div>
                    <div className={'news-img'}>
                        {!isMobile ? (<img src={earth5} alt={'illustration'} height={300} width={250}/>
                        ) : <img src={earth5} alt={'illustration'}/>

                        }
                    </div>
                </div>
            </div>)

                }

        </div>
        </div>
    );
}

export default NewsLetterForm;