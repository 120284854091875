import WertWidget from "@wert-io/widget-initializer";
import type { Options } from "@wert-io/widget-initializer/types";
import { signSmartContractData } from "@wert-io/widget-sc-signer";
import { v4 as uuidv4 } from "uuid";
import Web3 from "web3";
import { Buffer } from "buffer";
import * as ed from '@noble/ed25519';

window.Buffer = Buffer; // needed to use `signSmartContractData` in browser

/* We advise you not to use the private key on the frontend
    It is used here for example only
*/
const privateKey =
  "0x307835373436366166623534393165653337326233623330643832656637653761303538336339653336616566306630323433356264313634666531373262316433";

export const wertPay = async (amount: number) => {
  if (typeof window.ethereum !== "undefined") {
    try {
      const web3 = new Web3(window.ethereum);
      // Request account access if needed
      await web3.eth.requestAccounts();

      const accounts = await web3.eth.getAccounts();
      if (accounts.length === 0) {
        throw new Error(
          "No Ethereum accounts found. Please make sure you are connected to MetaMask or another Web3 wallet."
        );
      }

      const userAddress = accounts[0];
      if (!userAddress) {
        throw new Error(
          "Unable to retrieve user address. Please check your wallet connection."
        );
      };

              const privateKeys = ed.utils.randomPrivateKey(); // 32-byte Uint8Array or string
              const publicKey = await ed.getPublicKeyAsync(privateKeys);

            //  console.log(`Private key: 0x${Buffer.from(privateKey).toString('hex')}`);
            //  console.log(`Public key: 0x${Buffer.from(publicKey).toString('hex')}`);


      const amountinWei = web3.utils.toWei(amount.toString(), "ether");
      // Ensure userAddress is a string
      const userAddressString: string = userAddress.toString();

      const sc_input_data = web3.eth.abi.encodeFunctionCall(
        {
          inputs: [
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "buyWithBNB",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        [amountinWei]
      );

     // console.log("Signed data is", sc_input_data);

      const signedData = signSmartContractData(
        {
          address: userAddressString,
          commodity: "USDT",
          network: "bsc",
          commodity_amount: amount,
          sc_address: "0x5488da32B3186E32B74D39DAbd8443bb3a31918E",
          sc_input_data: sc_input_data,
        },
        privateKey
      );

      const otherWidgetOptions: Options = {
        partner_id: "01JAAN91XWXTQFYK36JBAHMGCF",
        click_id: uuidv4(),
        origin: "https://widget.wert.io", // this option needed only in sandbox

        listeners: {
          loaded: () => console.log("loaded"),
        },
      };

      const wertWidget = new WertWidget(
        // myoptions
        {
          ...signedData,
          ...otherWidgetOptions,
        }
      );

      wertWidget.open();
    } catch (error) {
      console.error("Error in wertPay:", error);
    }
  } else {
    console.error("Ethereum provider not found");
  }
};
