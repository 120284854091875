import React, { useEffect } from "react";

const ModalComponent = () => {

  //  const [showPopup, setShowPopup] = useState(false);

//     const handleShowPopup = () => {
//       setShowPopup(true);
//     };
  
//     const handleClosePopup = () => {
//       setShowPopup(false);
//     };

//     const handleCloseAndWert = () => {
//         setShowPopup(false);
//    //     wertPay();
//       };

  return (
    <>
      
{/* 
        {showPopup && (
          
            
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
                      Modal 1
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClosePopup}
                    ></button>
                  </div>
                  <div class="modal-body">
                    Show a second modal and hide this one with the button below.
                  </div>
                  <div class="modal-footer">
                    <button
                      class="btn btn-primary"
                      data-bs-target="#exampleModalToggle2"
                      data-bs-toggle="modal"
                      onClick={handleCloseAndWert} 
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
              
            
          
        )} */}
      

     
    </>
  );
};

export default ModalComponent;
